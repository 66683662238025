export default {

    "en": {
        "linklogo":         "pfleiderer.com/facade-configurator",
        "facadetop":        "Facade Top",
        "facadebottom":     "Facade bottom",
        "facadeleft":       "Facade left ",
        "facaderight":      "Facade right",
        "facadeinner":      "Inner facade",
        "facadeouter":      "Outer facade",
        "facadeall":        "Whole facade",

        "compare":          "Compare",
        "save":             "Save",
        "print":            "Print",
        "fullscreen":       "Fullscreen",
        "contact":          "Contact", 
        "help":             "Help",
  
        "de":               "DE",
        "en":               "EN",
        "fr":               "FR",
        "it":               "IT",
        "nl":               "NL",
        "cs":               "CZ",
        "zh":               "CN",
        "selectobject":     "Selectobject",
        "productlist":      "Productlist",

        // Choose Object
        "chooseobject":     "Please choose an object",
        "house":            "House",
        "residential":      "Residential",
        "hotel":            "Hotel",
        "office":           "Office & Education",
        "kindergarden":     "Kindergarden",
        "industryhall":     "Industry hall",
        "balcony":          "balcony & parapet", 
        

        // Sidebar
        "pattern":          "Pattern",
        "decor":            "Decor",
        "decors":           "Dekors",
        "mounting":         "Mounting",

        "patternmixed":      "Mixed",
        "decorrotate":       "Rotate decor",
        "decorchange":       "Change decor",
        "decoradd":         "Add decor",
        "distribution":      "Distribution",
        "style":             "Style",
        "ordered":           "ordered",
        "mixed":             "mixed",

        "rivets":             "Rivets",
        "screws":             "Screws",
        "invisiblehandles":   "Unvisible handles",
        "m1":                 "Aluminium substructure\n16 mm rivet with\nfixed point sleeves",
        "m2":                 "Aluminium substructure\n14 mm rivets",
        "m3":                 "Wood substructure\n12 mm façade screws",
        "m4":                 "Aluminium substructure\nconcealed mounting with\nTUF-S system",


        // generell
        "ready":             "Ready",
        "back":              "Back",
        "next":              "Next",
        "select":            "Select",
        "close":             "Close",
        "deleteall":         "Delete all",

        "project":           "Project",
        "projectload":       "Load project",
        "projectdelete":     "Delete project",
        "projectsave":       "Save project",
        "myprojects":        "My projects",

        "myproductlist":     "My product list",
        "ordersamples":      "Order samples",
        "expulsion":         "Expulsion text",
        "structure":         "Structure",
        "format":            "Format (mm)",
        "product":           "Product",
        "producthint":       "Note: For buildings over 7 meters height, Duropal XTerior compact F should be used on one single side painting due to fire protection regulations.",   

        "compareinfo":       "Speichern Sie ein Projekt, um die Vergleichen-Funktion nutzen zu können.",

        "shoppingcartinfo":   "This article was added to your shoppingcart",
        "gotoshoppingcart":   "Go to shoppingcart",
        "shoppingcart":       "Basket",  

        "logininfo":          "To save your configuration, call it up again at a later date and change it or compare it with other configurations, please log in.",
        "login":              "Log in",
        "register":           "register",
        "cancel":             "Cancel",

        "duropal-xterior-compact-single-sided-lacquering":     "Duropal XTerior compact – single-sided lacquering, 8mm",
        "duropal-xterior-compact-double-sided-lacquering":     "Duropal XTerior compact – double-sided lacquering, 10mm",
        "duropal-xterior-compact-f-single-sided-lacquering":   "Duropal XTerior compact F – single-sided lacquering, 8mm",
        
        "pattern1":            "Occupancy pattern 1",
        "pattern2":            "Occupancy pattern 2",
        "pattern3":            "Occupancy pattern 3",
        "pattern4":            "Occupancy pattern 4",

        "moreaboutHPL":         "More about HPL facade panels"

    },









    "de": {
        "linklogo":         "pfleiderer.com/fassadenkonfigurator",
        "facadetop":        "Obere Fassade",
        "facadebottom":     "Untere Fassade",
        "facadeleft":       "Linke Fassade",
        "facaderight":      "Rechte Fassade",
        "facadeinner":      "Innerer Bereich",
        "facadeouter":      "Äußerer Bereich",
        "facadeall":        "Ganzes Gebäude",

        "compare":          "Vergleichen",
        "save":             "Speichern",
        "print":            "Drucken",
        "fullscreen":       "Vollbild",
        "contact":          "Kontakt", 
        "help":             "Anleitung",               
        "de":               "DE",
        "en":               "EN",
        "fr":               "FR",
        "it":               "IT",
        "nl":               "NL",
        "cs":               "CZ",
        "zh":               "CN",
        "selectobject":     "Objektauswahl",
        "productlist":      "Produktliste",

        "chooseobject":     "Wählen Sie ein Objekt",
        "house":            "Einfamilienhaus",
        "residential":      "Wohnbau mehrgeschossig",
        "hotel":            "Hotel & Gastro",
        "office":           "Büro & Bildung",
        "kindergarden":     "Kindergarten",
        "industryhall":     "Industriehalle",
        "balcony":          "Balkone & Brüstungen",
        
        // Sidebar
        "pattern":          "Belegungsmuster",
        "decor":            "Dekor",
        "decors":           "Dekore",
        "mounting":         "Befestigung",

        "patternmixed":      "Durchmischt",
        "decorrotate":       "Dekor drehen",
        "decorchange":       "Dekor ändern",
        "decoradd":          "Dekor hinzufügen",
        "distribution":      "Anteil",
        "style":             "Stil",
        "ordered":           "geordnet",
        "mixed":             "durchmischt",

        "rivets":             "Nieten",
        "screws":             "Schrauben",
        "invisiblehandles":   "Unsichtbare Halter",
        "m1":                 "Alu-Unterkonstruktion\n16mm Niete \nmit Festpunkthülsen",
        "m2":                 "Alu-Unterkonstruktion\n14mm Niete",
        "m3":                 "Holz-Unterkonstruktion\n12mm\nFassadenschrauben",
        "m4":                 "Alu-Unterkonstruktion\nverdeckte Befestigung mit\nTUF-S System", 

        // generell
        "ready":             "Fertig",
        "back":              "Zurück",
        "next":              "Weiter",
        "select":            "Auswählen",
        "close":             "Schließen",
        "deleteall":         "Alle löschen",

        "project":           "Projekt",
        "projectload":       "Projekt laden",
        "projectdelete":     "Projekt löschen",
        "projectsave":       "Projekt speichern",
        "myprojects":        "Meine Projekte",

        "myproductlist":      "Meine Produktliste",
        "ordersamples":      "Muster bestellen",
        "expulsion":         "Ausschreibungstext",
        "structure":         "Struktur",
        "format":            "Format (mm)",
        "product":           "Produkt",
        "producthint":       "Hinweis: Bei Gebäuden über 7 Meter sollte auf Grund der Brandschutzbestimmungen Duropal XTerior compact F - einseitige Lackierung gewählt werden.", 

        "compareinfo":        "Speichern Sie ein Projekt, um die Vergleichen-Funktion nutzen zu können.",

        "shoppingcartinfo":   "Dieser Artikel wurde Ihrem Warenkorb hinzugefügt",
        "gotoshoppingcart":   "Zum Warenkorb",
        "shoppingcart":       "Warenkorb",
        
        "logininfo":          "Um Ihre Konfiguration zu speichern, zu einem späteren Zeitpunkt nochmals aufzurufen und zu ändern oder mit anderen Konfigurationen zu vergleichen, loggen Sie sich bitte ein.",
        "login":              "Log in",
        "register":           "Registrieren",
        "cancel":             "Abbrechen", 
       
        "duropal-xterior-compact-single-sided-lacquering": "Duropal XTerior compact – einseitige Lackierung, 8mm",
        "duropal-xterior-compact-double-sided-lacquering": "Duropal XTerior compact – beidseitige Lackierung, 10mm",
        "duropal-xterior-compact-f-single-sided-lacquering": "Duropal XTerior compact F – einseitige Lackierung, 8mm",
        
        "pattern1":            "Belegungsmuster 1",
        "pattern2":            "Belegungsmuster 2",
        "pattern3":            "Belegungsmuster 3",
        "pattern4":            "Belegungsmuster 4",

        "moreaboutHPL":        "Erfahren Sie mehr zu HPL Fassadenplatten"
    },







    "fr": {
        "linklogo":         "pfleiderer.com/configurateur-de-facade",
        "facadetop":        "Façade supérieure",
        "facadebottom":     "Façade inférieure",
        "facadeleft":       "Façade gauche",
        "facaderight":      "Façade droite",
        "facadeinner":      "Zone intérieure",
        "facadeouter":      "Zone extérieure",
        "facadeall":        "Bâtiment entier",

        "compare":          "Comparer",
        "save":             "Enregistrer",
        "print":            "Imprimer",
        "fullscreen":       "Plein écran",
        "contact":          "Contact", 
        "help":             "Instructions",    
        "de":               "DE",
        "en":               "EN",
        "fr":               "FR",
        "it":               "IT",
        "nl":               "NL",
        "cs":               "CZ",
        "zh":               "CN",
        "selectobject":     "Choix de l'objet",
        "productlist":      "Liste des produits",

        // Choose Object
        "chooseobject":     "Sélectionnez un objet",
        "house":            "Maison individuelle",
        "residential":      "Immeuble d'habitation à plusieurs étages",
        "hotel":            "Hôtel & restauration",
        "office":           "Bureaux & éducation",
        "kindergarden":     "Jardin d'enfants",
        "industryhall":     "Hall industriel",
        "balcony":          "Balcons & parapets", 
        

        // Sidebar
        "pattern":          "Modèle d'occupation",
        "decor":            "Décor",
        "decors":           "Décors",
        "mounting":         "Fixation",

        "patternmixed":      "Mélangé",
        "decorrotate":       "Tourner le décor",
        "decorchange":       "Modifier le décor",
        "decoradd":          "Ajouter un décor",
        "distribution":      "Part",
        "style":             "Style",
        "ordered":           "ordonné",
        "mixed":             "mélangé",

        "rivets":             "Rivets",
        "screws":             "Vis",
        "invisiblehandles":   "Supports invisibles",
        "m1":                 "Structure porteuse en alu\nRivets de 16 mm avec\ndouilles à point fixe de",
        "m2":                 "Structure porteuse en alu\nRivets de 14 mm",
        "m3":                 "Structure porteuseen bois\nVis de façade \nde 12 mm",
        "m4":                 "Sous-construction en aluminium\nfixation invisible avec\nsystème TUF-S",  


        // generell
        "ready":             "Prêt à l'emploi",
        "back":              "Retour",
        "next":              "Suivant",
        "select":            "Sélectionner",
        "close":             "Fermer",
        "deleteall":         "Supprimer tout",

        "project":           "Projet",
        "projectload":       "Charger un projet",
        "projectdelete":     "Supprimer un projet",
        "projectsave":       "Enregistrer le projet",
        "myprojects":        "Mes projets",

        "myproductlist":     "Ma liste de produits",
        "ordersamples":      "Commander des échantillons",
        "expulsion":         "Texte d'appel d'offres",
        "structure":         "Structure",
        "format":            "Format (mm)",
        "product":           "Produit",
        "producthint":       "Remarque: Pour les bâtiments de plus de 7 mètres, il convient de choisir le Duropal XTerior compact F - laquage sur une face, en raison des dispositions en matière de protection incendie.",   

        "compareinfo":       "Enregistrez un projet pour pouvoir utiliser la fonction \"Comparer\".",

        "shoppingcartinfo":   "Cet article a été ajouté à votre panier",
        "gotoshoppingcart":   "Aller au panier d'achat",
        "shoppingcart":       "Panier",

        "logininfo":          "Pour enregistrer votre configuration, la consulter et la modifier ultérieurement ou la comparer à d'autres configurations, veuillez vous connecter.",
        "login":              "Se connecter",
        "register":           "S'inscrire",
        "cancel":             "Annuler",

        "duropal-xterior-compact-single-sided-lacquering":     "Duropal XTerior compact – laqué une face, 8 mm",
        "duropal-xterior-compact-double-sided-lacquering":     "Duropal XTerior compact – laqué deux faces, 10 mm",
        "duropal-xterior-compact-f-single-sided-lacquering":   "Duropal XTerior compact F – laqué une face, 8 mm",
        
        "pattern1":            "Modèle d'occupation 1",
        "pattern2":            "Modèle d'occupation 2",
        "pattern3":            "Modèle d'occupation 3",
        "pattern4":            "Modèle d'occupation 4",

        "moreaboutHPL":        "En savoir plus sur nos panneaux de façade"
    },






    
    "it": {
        "linklogo":         "pfleiderer.com/configuratore-di-facciata",
        "facadetop":        "Facciata superiore",
        "facadebottom":     "Facciata inferiore",
        "facadeleft":       "Facciata sinistra",
        "facaderight":      "Facciata destra",
        "facadeinner":      "Area interna",
        "facadeouter":      "Area esterna",
        "facadeall":        "Intero edificio",

        "compare":          "Confronto",
        "save":             "Risparmiare",
        "print":            "Stampa",
        "fullscreen":       "Schermo intero",
        "contact":          "Contatto", 
        "help":             "Istruzione",       
        "de":               "DE",
        "en":               "EN",
        "fr":               "FR",
        "it":               "IT",
        "nl":               "NL",
        "cs":               "CZ",
        "zh":               "CN",
        "selectobject":     "Selezione dell'oggetto",
        "productlist":      "Elenco prodotti",

        // Choose Object
        "chooseobject":     "Selezionare un oggetto",
        "house":            "Casa unifamiliare",
        "residential":      "Edificio residenziale a più piani",
        "hotel":            "Albergo e gastronomia",
        "office":           "Ufficio e istruzione",
        "kindergarden":     "Asilo nido",
        "industryhall":     "Capannone industriale",
        "balcony":          "Balconi e balaustre", 
        

        // Sidebar
        "pattern":          "Schema di occupazione",
        "decor":            "Decorazioni",
        "decors":           "Decori",
        "mounting":         "Fissaggio",

        "patternmixed":      "Misto",
        "decorrotate":       "Ruotare il decoro",
        "decorchange":       "Cambiare il decoro",
        "decoradd":          "Aggiungere decori",
        "distribution":      "Condividere",
        "style":             "Stile",
        "ordered":           "ordinato",
        "mixed":             "mescolato",

        "rivets":             "Rivetti",
        "screws":             "Viti",
        "invisiblehandles":   "Supporti invisibili",
        "m1":                 "Sottostruttura in alluminio\nRivetto 16 mm con\nbussole a punto fisso\n",
        "m2":                 "Sottostruttura in alluminio\nRivetto 14 mm",
        "m3":                 "Sottostruttura in legno\nViti per facciate 12 mm",
        "m4":                 "Sottostruttura in alluminio\nFissaggio a scomparsa con\nsistema TUF-S",  

        // generell
        "ready":             "Pronti",
        "back":              "Indietro",
        "next":              "Avanti",
        "select":            "Selezionare",
        "close":             "Chiudere",
        "deleteall":         "Cancella tutto",

        "project":           "Progetto",
        "projectload":       "Carica progetto",
        "projectdelete":     "Cancellare il progetto",
        "projectsave":       "Salvare il progetto",
        "myprojects":        "I miei progetti",

        "myproductlist":     "Il mio elenco di prodotti",
        "ordersamples":      "Campione d'ordine",
        "expulsion":         "Testo dell'offerta",
        "structure":         "Struttura",
        "format":            "Formato (mm)",
        "product":           "Prodotto",
        "producthint":       "Nota: Per gli edifici di altezza superiore a 7 metri, è necessario scegliere Duropal XTerior compact F - rivestimento monofacciale a causa delle norme antincendio.",   

        "compareinfo":       "Salvare un progetto per poter utilizzare la funzione di confronto",

        "shoppingcartinfo":   "Questo articolo è stato aggiunto al tuo carrello",
        "gotoshoppingcart":   "Vai al carrello",
        "shoppingcart":       "Carrello",

        "logininfo":          "Per salvare la configurazione, richiamarla e modificarla in un secondo momento o confrontarla con altre configurazioni, effettuare il login.",
        "login":              "Accesso",
        "register":           "Registrazione",
        "cancel":             "Annullamento",

        "duropal-xterior-compact-single-sided-lacquering":     "Duropal XTerior compact – laccatura un lato, 8 mm",
        "duropal-xterior-compact-double-sided-lacquering":     "Duropal XTerior compact – laccatura due lati, 10 mm",
        "duropal-xterior-compact-f-single-sided-lacquering":   "Duropal XTerior compact F – laccatura un lato, 8 mm",
        
        "pattern1":            "Schema di occupazione 1",
        "pattern2":            "Schema di occupazione 2",
        "pattern3":            "Schema di occupazione 3",
        "pattern4":            "Schema di occupazione 4",

        "moreaboutHPL":        "Di più sui pannelli per facciate"
    },












    "cs": {
        "linklogo":         "pfleiderer.com/konfigurator-fasady",
        "facadetop":        "Horní fasáda",
        "facadebottom":     "Spodní fasáda",
        "facadeleft":       "Levá fasáda",
        "facaderight":      "Pravá fasáda",
        "facadeinner":      "Vnitřní prostor",
        "facadeouter":      "Vnější plocha",
        "facadeall":        "Celá budova",

        "compare":          "Porovnat",
        "save":             "Uložit",
        "print":            "Vytisknout",
        "fullscreen":       "Celá obrazovka",
        "contact":          "Kontakt ", 
        "help":             "Pokyny",       
        "de":               "DE",
        "en":               "EN",
        "fr":               "FR",
        "it":               "IT",
        "nl":               "NL",
        "cs":               "CZ",
        "zh":               "CN",
        "selectobject":     "Výběr objektu",
        "productlist":      "Seznam produktů",

        // Choose Object
        "chooseobject":     "Výběr objektu",
        "house":            "Dům pro jednu rodinu",
        "residential":      "Vícepodlažní obytná budova",
        "hotel":            "Hotel & Gastro",
        "office":           "Kanceláře a vzdělávání",
        "kindergarden":     "Mateřská škola",
        "industryhall":     "Průmyslová hala",
        "balcony":          "Balkony a zábradlí", 
        

        // Sidebar
        "pattern":          "Vzor obsazenosti",
        "decor":            "Dekor",
        "decors":           "Dekory",
        "mounting":         "Upevnění",

        "patternmixed":      "Smíšené",
        "decorrotate":       "Otáčení dekoru",
        "decorchange":       "Změna dekoru",
        "decoradd":          "Přidat dekor",
        "distribution":      "Sdílet",
        "style":             "Styl",
        "ordered":           "objednat",
        "mixed":             "smíšené",

        "rivets":             "Nýty",
        "screws":             "Šrouby",
        "invisiblehandles":   "Neviditelné držáky",
        "m1":                 "hliníková spodní konstrukce\n16 mm nýt s\n10 mm pouzdry s pevným bodem\n",
        "m2":                 "hliníková spodní konstrukce\n14 mm ",
        "m3":                 "dřevěná spodní konstrukce\n12 mm fasádní šrouby",
        "m4":                 "Hliníková nosná konstrukce\nSkryté upevnění pomocí\nsystému TUF-S",  


        // generell
        "ready":             "Připraveno",
        "back":              "Zpět",
        "next":              "Další",
        "select":            "Vyberte si",
        "close":             "Zavřít",
        "deleteall":         "Smazat vše",

        "project":           "Projekt",
        "projectload":       "Načíst projekt",
        "projectdelete":     "Smazat projekt",
        "projectsave":       "Uložit projekt",
        "myprojects":        "Moje projekty",

        "myproductlist":     "Můj seznam produktů",
        "ordersamples":      "Objednat vzorek",
        "expulsion":         "Text nabídky",
        "structure":         "Struktura",
        "format":            "Formát (mm)",
        "product":           "Výrobek",
        "producthint":       "Poznámka: Pro budovy nad 7 metrů je třeba zvolit Duropal XTerior compact F - jednostranný nátěr z důvodu protipožárních předpisů.",   

        "compareinfo":       "Uložte projekt, abyste mohli použít funkci porovnání.",

        "shoppingcartinfo":   "Tento článek byl přidán do nákupního košíku",
        "gotoshoppingcart":   "Přejít do nákupního košíku",
        "shoppingcart":       "Nakupování",

        "logininfo":          "Chcete-li uložit svou konfiguraci, později ji vyvolat a změnit nebo porovnat s jinými konfiguracemi, přihlaste se.",
        "login":              "Přihlášení",
        "register":           "Zaregistrujte se na adrese",
        "cancel":             "Zrušit",

        "duropal-xterior-compact-single-sided-lacquering":     "Duropal XTerior compact – jednostranné lakování, 8 mm",
        "duropal-xterior-compact-double-sided-lacquering":     "Duropal XTerior compact – oboustranné lakování, 10 mm",
        "duropal-xterior-compact-f-single-sided-lacquering":   "Duropal XTerior compact F – jednostranné lakování, 8 mm",
        
        "pattern1":            "Vzor obsazenosti 1",
        "pattern2":            "Vzor obsazenosti 2",
        "pattern3":            "Vzor obsazenosti 3",
        "pattern4":            "Vzor obsazenosti 4",

        "moreaboutHPL":        "Další informace o fasádních deskách HPL"
    },













    "nl": {
        "linklogo":         "pfleiderer.com/gevelconfigurator",
        "facadetop":        "Bovenste gevel",
        "facadebottom":     "Onderste gevel",
        "facadeleft":       "Linker gevel",
        "facaderight":      "Rechter gevel",
        "facadeinner":      "Binnengebied",
        "facadeouter":      "Buitengebied",
        "facadeall":        "Gehele gebouw",

        "compare":          "Vergelijk",
        "save":             "Opslaan",
        "print":            "Afdrukken",
        "fullscreen":       "Volledig scherm",
        "contact":          "Contact", 
        "help":             "Instructie",       
        "de":               "DE",
        "en":               "EN",
        "fr":               "FR",
        "it":               "IT",
        "nl":               "NL",
        "cs":               "CZ",
        "zh":               "CN",
        "selectobject":     "Object selectie",
        "productlist":      "Productlijst",

        // Choose Object
        "chooseobject":     "Selecteer een object",
        "house":            "Eengezinswoning",
        "residential":      "Woongebouw met meerdere verdiepingen",
        "hotel":            "Hotel & Gastro",
        "office":           "Kantoor & Onderwijs",
        "kindergarden":     "Kleuterschool",
        "industryhall":     "Industriële hal",
        "balcony":          "Balkons & balustrades", 
        

        // Sidebar
        "pattern":          "Bewoningspatroon",
        "decor":            "Decor",
        "decors":           "Decors",
        "mounting":         "Bevestiging",

        "patternmixed":      "Gemengd",
        "decorrotate":       "Decor draaien",
        "decorchange":       "Decor veranderen",
        "decoradd":          "Decor toevoegen",
        "distribution":      "Delen",
        "style":             "Stijl",
        "ordered":           "geordend",
        "mixed":             "gemengd",

        "rivets":             "Klinknagels",
        "screws":             "Schroeven",
        "invisiblehandles":   "Onzichtbare houders",
        "m1":                 "Aluminium onderconstructie\n16 mm klinknagel met\nvaste punthulzen",
        "m2":                 "Aluminium onderconstructie\n14 mm klinknagel",
        "m3":                 "Houten onderconstructie\n12 mm gevelschroeven",
        "m4":                 "Aluminium onderbouw\nVerborgen bevestiging met\nTUF-S systeem",  


        // generell
        "ready":             "Klaar",
        "back":              "Terug",
        "next":              "Volgende",
        "select":            "Selecteer",
        "close":             "Sluit",
        "deleteall":         "Alles verwijderen",

        "project":           "Project",
        "projectload":       "Project laden",
        "projectdelete":     "Project verwijderen",
        "projectsave":       "Project opslaan",
        "myprojects":        "Mijn projecten",

        "myproductlist":     "Mijn productlijst",
        "ordersamples":      "Bestelvoorbeeld",
        "expulsion":         "Aanbestedingstekst",
        "structure":         "Structuur",
        "format":            "Formaat (mm)",
        "product":           "Product",
        "producthint":       "Opmerking: Voor gebouwen hoger dan 7 meter moet Duropal XTerior compact F - enkelzijdige coating worden gekozen vanwege de brandbeveiligingsvoorschriften.",   

        "compareinfo":       "Sla een project op om de vergelijkingsfunctie te kunnen gebruiken",

        "shoppingcartinfo":   "Dit artikel is toegevoegd aan uw winkelwagen",
        "gotoshoppingcart":   "Naar winkelwagen",
        "shoppingcart":       "Winkelmandje",

        "logininfo":          "Om je configuratie op te slaan, op een later tijdstip op te roepen en te wijzigen of te vergelijken met andere configuraties, moet je inloggen.",
        "login":              "Aanmelden",
        "register":           "Registreren",
        "cancel":             "Annuleren",

        "duropal-xterior-compact-single-sided-lacquering":     "Duropal XTerior compact – eenzijdig gelakt, 8 mm",
        "duropal-xterior-compact-double-sided-lacquering":     "Duropal XTerior compact – tweezijdig gelakt, 10 mm",
        "duropal-xterior-compact-f-single-sided-lacquering":   "Duropal XTerior compact F – eenzijdig gelakt, 8 mm",
        
        "pattern1":            "Bewoningspatroon 1",
        "pattern2":            "Bewoningspatroon 2",
        "pattern3":            "Bewoningspatroon 3",
        "pattern4":            "Bewoningspatroon 4",

        "moreaboutHPL":        "Meer informatie over HPL gevelpanelen"
    },










    
    "zh": {
        "linklogo":         "https://www.pfleiderer.com/facade-configurator/chn",
        "facadetop":        "上层外墙",
        "facadebottom":     "下部外墙",
        "facadeleft":       "左立面",
        "facaderight":      "右侧门面",
        "facadeinner":      "内部区域",
        "facadeouter":      "外部区域",
        "facadeall":        "整个建筑",

        "compare":          "比较",
        "save":             "保存",
        "print":            "打印图片",
        "fullscreen":       "全屏",
        "contact":          "联系我们", 
        "help":             "指南",     
        "de":               "DE",
        "en":               "EN",
        "fr":               "FR",
        "it":               "IT",
        "nl":               "NL",
        "cs":               "CZ",
        "zh":               "CN",
        "selectobject":     "对象选择",
        "productlist":      "产品列表",

        // Choose Object
        "chooseobject":     "选择一个对象",
        "house":            "单户住宅",
        "residential":      "多层住宅楼",
        "hotel":            "酒店与美食",
        "office":           "办公室与教育",
        "kindergarden":     "幼儿园",
        "industryhall":     "工业大厅",
        "balcony":          "阳台和栏杆", 
        

        // Sidebar
        "pattern":          "占用模式",
        "decor":            "装饰",
        "decors":           "装饰",
        "mounting":         "固定装置",

        "patternmixed":      "混合型",
        "decorrotate":       "旋转装饰",
        "decorchange":       "改变装饰",
        "decoradd":          "添加装饰",
        "distribution":      "分享",
        "style":             "风格",
        "ordered":           "有序",
        "mixed":             "混杂的",

        "rivets":             "铆钉",
        "screws":             "螺丝钉",
        "invisiblehandles":   "隐形支架",
        "m1":                 "铝制下部结构\n16毫米铆钉\n固定点套筒",
        "m2":                 "铝制下部结构\n14毫米",
        "m3":                 "木材底层结构\n12毫米外墙螺丝",
        "m4":                 "铝制下部结构\n采用TUF-S系统隐蔽式紧固",


        // generell
        "ready":             "准备就绪",
        "back":              "返回",
        "next":              "下一页",
        "select":            "选择",
        "close":             "关闭",
        "deleteall":         "删除所有",

        "project":           "项目",
        "projectload":       "加载项目",
        "projectdelete":     "删除项目",
        "projectsave":       "保存项目",
        "myprojects":        "我的项目",

        "myproductlist":     "我的产品列表",
        "ordersamples":      "订单样本",
        "expulsion":         "招标文本",
        "structure":         "结构",
        "format":            "格式(mm)",
        "product":           "产品",
        "producthint":       "注：对于超过7米的建筑，由于防火规定，应选择Duropal XTerior compact F - 单面涂层。",   

        "compareinfo":       "保存一个项目，以便能够使用比较功能",

        "shoppingcartinfo":   "这篇文章已被添加到您的购物车中",
        "gotoshoppingcart":   "转到购物车",
        "shoppingcart":       "购物车",

        "logininfo":          "要保存您的配置，在以后的时间里调用和改变它，或与其他配置进行比较，请登录。",
        "login":              "登录",
        "register":           "注册",
        "cancel":             "取消",

        "duropal-xterior-compact-single-sided-lacquering":     "Duropal XTerior compact – 单面涂漆 8毫米",
        "duropal-xterior-compact-double-sided-lacquering":     "Duropal XTerior compact – 双面涂漆 10毫米",
        "duropal-xterior-compact-f-single-sided-lacquering":   "Duropal XTerior compact F – 单面涂漆 8毫米",
        
        "pattern1":            "占用模式 1",
        "pattern2":            "占用模式 2",
        "pattern3":            "占用模式 3",
        "pattern4":            "占用模式 4",

        "moreaboutHPL":        "外墙板的更多信息"
    },





    "pl": {
        "linklogo":         "pfleiderer.com/konfigurator-elewacji",
        "facadetop":        "Górna fasada",
        "facadebottom":     "Dolna fasada",
        "facadeleft":       "Lewa fasada",
        "facaderight":      "Prawa fasada",
        "facadeinner":      "Obszar wewnętrzny",
        "facadeouter":      "Obszar zewnętrzny",
        "facadeall":        "Cały budynek",

        "compare":          "Porównaj",
        "save":             "Zapisz",
        "print":            "Drukuj",
        "fullscreen":       "Pełny ekran",
        "contact":          "Kontakt", 
        "help":             "Instrukcja",       
        "de":               "DE",
        "en":               "EN",
        "fr":               "FR",
        "it":               "IT",
        "nl":               "NL",
        "cs":               "CZ",
        "zh":               "CN",
        "selectobject":     "Wybór obiektu",
        "productlist":      "Lista produktów",

        // Choose Object
        "chooseobject":     "Wybierz obiekt",
        "house":            "Dom jednorodzinny",
        "residential":      "Budynek wielopiętrowy",
        "hotel":            "Hotel i gastronomia",
        "office":           "Biuro i edukacja",
        "kindergarden":     "Przedszkole",
        "industryhall":     "Hala przemysłowa",
        "balcony":          "Balkony i balustrady", 
        

        // Sidebar
        "pattern":          "Układ wypełnienia",
        "decor":            "Dekor",
        "decors":           "Dekory",
        "mounting":         "Mocowanie",

        "patternmixed":      "Mieszany",
        "decorrotate":       "Obróć dekor",
        "decorchange":       "Zmień dekor",
        "decoradd":          "Dodaj dekor",
        "distribution":      "Udział",
        "style":             "Styl",
        "ordered":           "Uporządkowany",
        "mixed":             "Mieszany",

        "rivets":             "Nitiy",
        "screws":             "Wkręty",
        "invisiblehandles":   "Ukryte mocowanie",
        "m1":                 "Aluminiowa konstrukcja nośna \n16 mm nity z tulejkami punktowymi",
        "m2":                 "Aluminiowa konstrukcja nośna \n14 mm nity",
        "m3":                 "Drewniana konstrukcja nośna \n12 mm wkręty fasadowe",
        "m4":                 "Aluminiowa konstrukcja \nnośna Ukryte mocowanie z \nsystemem TUF-S",  


        // generell
        "ready":             "Gotowe",
        "back":              "Wstecz",
        "next":              "Dalej",
        "select":            "Wybierz",
        "close":             "Zamknij",
        "deleteall":         "Usuń wszystko",

        "project":           "Projekt",
        "projectload":       "Załaduj projekt",
        "projectdelete":     "Usuń projekt",
        "projectsave":       "Zapisz projekt",
        "myprojects":        "Moje projekty",

        "myproductlist":     "Moja lista produktów",
        "ordersamples":      "Zamów próbkę",
        "expulsion":         "Tekst przetargowy",
        "structure":         "Struktura",
        "format":            "Format (mm)",
        "product":           "Produkt",
        "producthint":       "Uwaga: W przypadku budynków o wysokości powyżej 7 metrów, zgodnie z przepisami przeciwpożarowymi, należy wybrać - Duropal XTerior compact F – lakierowanie jednostronne.",   

        "compareinfo":       "Zapisz projekt, aby móc skorzystać z funkcji porównania.",

        "shoppingcartinfo":   "Ten artykuł został dodany do Twojego koszyka.",
        "gotoshoppingcart":   "Przejdź do koszyka",
        "shoppingcart":       "Koszyk",

        "logininfo":          "Aby zapisać konfigurację, ponownie ją otworzyć w późniejszym terminie, zmodyfikować lub porównać z innymi konfiguracjami, proszę się zalogować.",
        "login":              "Logowanie",
        "register":           "Zarejestruj się",
        "cancel":             "Anuluj",

        "duropal-xterior-compact-single-sided-lacquering":     "Duropal XTerior compact – lakierowanie jednostronne, 8 mm",
        "duropal-xterior-compact-double-sided-lacquering":     "Duropal XTerior compact – lakierowanie obustronne, 10 mm",
        "duropal-xterior-compact-f-single-sided-lacquering":   "Duropal XTerior compact F – lakierowanie jednostronne, 8 mm",
        
        "pattern1":            "Wzór wypełnienia 1",
        "pattern2":            "Wzór wypełnienia 2",
        "pattern3":            "Wzór wypełnienia 3",
        "pattern4":            "Wzór wypełnienia 4",

        "moreaboutHPL":        "Dowiedz się więcej o płytach fasadowych HPL"
    },




}